import { Article } from '@/Article';
import { Seo } from '@/layout/Seo';
import { caption } from '@/layout/styles/Typography';
import { Tag } from '@/Tag';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Fragment } from 'react';

const Date = styled.p`
    ${caption};
    margin-bottom: 0;
`;

type BlogPostProps = {
    data: {
        mdx: {
            frontmatter: {
                title: string;
                seoTitle: string;
                seoDescription: string;
                date: string;
                cover: {
                    og: {
                        gatsbyImageData: IGatsbyImageData;
                    };
                    hero: ImageDataLike;
                };
            };
            excerpt: string;
            body: string & React.ReactNode;
        };
    };
};

export const BlogPost = ({ data }: BlogPostProps) => {
    const {
        mdx: {
            frontmatter: { title, seoTitle, seoDescription, cover, date },
            excerpt,
            body,
        },
    } = data;

    return (
        <Fragment>
            <Seo
                title={seoTitle ? seoTitle : title}
                description={seoDescription ? seoDescription : excerpt}
                image={cover.og.gatsbyImageData}
            />
            <Article post>
                <Tag text="Blog" />
                <Date>{date}</Date>
                <h2>{title}</h2>
                <GatsbyImage
                    image={getImage(cover.hero) as IGatsbyImageData}
                    alt="simhairy dental"
                    loading="eager"
                />
                <MDXRenderer>{body}</MDXRenderer>
            </Article>
        </Fragment>
    );
};

export default BlogPost;

export const query = graphql`
    query ($slug: String!) {
        mdx(fileAbsolutePath: { regex: $slug }) {
            frontmatter {
                title
                seoTitle
                seoDescription
                date(formatString: "DD MMMM YYYY")
                cover {
                    og: childImageSharp {
                        gatsbyImageData(width: 1200, height: 630)
                    }
                    hero: childImageSharp {
                        gatsbyImageData(layout: FULL_WIDTH)
                    }
                }
            }
            excerpt
            body
        }
    }
`;
